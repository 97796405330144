import React, { useEffect, useState } from 'react'
import Filter from '../../../helper/filter/Filter'
import { Modal } from 'react-bootstrap';
import FilterModal from './FilterModal';
import { GrEdit } from "react-icons/gr";
import { AiOutlineDelete, AiTwotoneFileImage } from "react-icons/ai";
import DataTable from 'react-data-table-component';
import { columns } from 'fontawesome';
import { ApiFilterList } from '../../../api-wrapper/campaign/ApiCampaignName';
import { handleLoader } from '../../../redux/action';
import Toast from '../../../helper/toast/Toast';
import { useDispatch } from 'react-redux';
import { ApideleteRule } from '../../../api-wrapper/campaign/ApiFilter';
import Button from 'react-bootstrap/Button';


function AddFilter() {
  const [campaignId, setcampaignId] = useState("")
  const [fromDate, setfromDate] = useState();
  const [toDate, settoDate] = useState();
  const [filterData, setfilterData] = useState([])
  const [profileId, setprofileId] = useState(localStorage.getItem("profileId") || "")
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch()

  const campaignIdHandler = (e) => {
    setcampaignId(e)
  }

  const dateHandler = (e) => {
    if (e) {
      setfromDate(e[0]);
      settoDate(e[1])
    }

  }

  const [addModalFlag, setaddModalFlag] = useState(false);
  const [editId, setEditId] = useState();



  const [conditionOperators, setConditionOperator] = useState([
    {
      key: "GREATER_THAN",
      value: ">",
    },
    {
      key: "LESS_THAN_OR_EQUAL_TO",
      value: "<=",
    },
    {
      key: "GREATER_THAN_OR_EQUAL_TO",
      value: ">=",
    },
    {
      key: "LESS_THAN",
      value: "<",
    },
    {
      key: "EQUAL_TO",
      value: "==",
    },
  ]);

  const mainConditionMaker = (
    index,
    fieldName,
    fieldOperator,
    fieldValue
  ) => {

    let getOperator = conditionOperators?.find((x) => x.key == fieldOperator).value;

    return (
      <div key={index}>
        {index == 0 ? "" : 'And'} {fieldName} {getOperator} {fieldValue}
      </div>
    );
  };

  const columns = [
    {
      name: "Filter Name",
      selector: (row) => row.filterName,
      sortable: true,
    },
    {
      name: "Days",
      selector: (row) => row.days,
      sortable: true,
    },

    {
      name: "Conditions",
      width: "230px",
      cell: (e) => {
        return (
          <div className='filter_type'>
            {e?.conditions?.map((el, index) => {
              return mainConditionMaker(

                index,
                el?.fieldName,
                el?.fieldOperator,
                el?.fieldValue
              )
            })}
          </div>
        );
      },
    },
    {
      name: "Sort By",
      selector: (row) => row.sorting,
      cell: (e) => {

        return (
          <>
            {`${e.sorting?.sortType == "ASCE" ? e.sorting?.sortName + "  Ascending" : e.sorting?.sortType == "DESC" ? e.sorting?.sortName + "  Descending" : "-"}`}
          </>
        )
      },
    },
    {
      name: "Action",
      selector: (row) => row.isActive,
      width: "250px",
      cell: (e) => (
        <div className="d-flex align-items-center">
          <GrEdit
            size={18}
            color={"green"}
            style={{ cursor: "pointer" }}
            className="me-2"
            onClick={() => editHandler(e)}
          />
          <AiOutlineDelete
            className="me-2"
            color={"red"}
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteId(e._id);
            }}
          />
          <AiTwotoneFileImage
            className="me-2"
            // onClick={()=>{
            //   HandleraddTemplate(e._id,e.isTemplate)
            // }}
            size={20}
            style={{ cursor: "pointer" }}
          />

        </div>
      ),
    },
  ];
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setcurrentPage] = useState(1);

  const editHandler = (rows) => {
    setaddModalFlag(true);
    setEditId(rows._id);
  }



  useEffect(() => {
    getFilterData()
  }, [])


  const getFilterData = async (page, perPage) => {
    let data = {
      pageNo: page || currentPage,
      perPage: perPage || rowsPerPage,
      profileId: profileId
    }
    await ApiFilterList(data).then((e) => {

      if (e?.isSuccess) {
        dispatch(handleLoader(false));
        setfilterData(e.data)
        setTotalRecords(e?.totalRecords);

      } else {
        dispatch(handleLoader(false));
        Toast.error(e?.message);
      }
    })
      .catch((e) => {
        dispatch(handleLoader(false));
        Toast.error("Somthing went wrong");
      });
  }
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClose = () => setShowDeleteModal(false);
  const handleShow = () => setShowDeleteModal(true);


  const deleteHandler = () => {
    dispatch(handleLoader(true));
    ApideleteRule(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          dispatch(handleLoader(false));
          getFilterData();

        } else {
          Toast.error(res.message);
          dispatch(handleLoader(false));
        }
        setDeleteId();
      })
      .catch((err) => {
        dispatch(handleLoader(false));
        Toast.error("something went wrong!");
      });
  };


  return (
    <>
      <div className='bg-white'>
        <Filter campaignIdHandler={campaignIdHandler} dateHandler={dateHandler} />
      </div>
      <div className='middle_container addfilter_container'>
        <div className='data_content data_content_btn'>
          <div className='data_modal_btn'>
            <h5>Filter</h5>
            <button className='modal_btn px-3' onClick={() => { setaddModalFlag(true); setEditId() }}>
              <i class="fa fa-plus pe-3"></i>
              Add Filter
            </button>
          </div>
          <DataTable
            striped={true}
            data={filterData}
            columns={columns}
          // pagination
          // paginationServer
          // onChangeRowsPerPage={(event) => { setRowsPerPage(parseInt(event)); getFilterData(currentPage, event); }}
          // paginationPerPage={rowsPerPage}
          // paginationTotalRows={totalRecords || 0}
          // onChangePage={(page) => { getFilterData(page) }}
          />
        </div>

        <Modal show={addModalFlag} onHide={() => setaddModalFlag(false)} centered size="xl" className='add_modal'>
          <Modal.Header className="row campaign_modal_head ">
            <div className="col-11 modal_title_box text-center">
              <p className='filter_modal_title'>Filter</p>
            </div>
            <div className="col-1 text-end">
              <i
                className="fa fa-times red modal_close_box py-3"
                aria-hidden="true"
                onClick={() => setaddModalFlag(false)}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <FilterModal
              setaddModalFlag={setaddModalFlag}
              getRule={getFilterData}
              setEditId={setEditId}
              editId={editId}

            />
          </Modal.Body>
        </Modal>



        <Modal show={showDeleteModal} onHide={handleClose} className='delete_modal'>
          <Modal.Header closeButton>
            <h5>Filters</h5>
          </Modal.Header>
          <Modal.Body>
            <h5 className='text-center'>  Are you sure want to delete this record? </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" onClick={() => { deleteHandler(); setShowDeleteModal(false) }}>
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default AddFilter
