/* eslint-disable */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { childBidHandler } from "../../../redux/action";
import moment from "moment-timezone";

function ExpandTable({
	arg,
	setArg,
	placementData,
	keywordBid,
	weekData,
	campaignData,
	setsubval,
	minTargetCPC,
	maxTargetCPC,
	campaignName,
	order,
	aovData,
	limitValue,
	setBulk,
	bulk,
	setcampaignData,
	TOSPercentage,
	defaultTargetAcos,
	HandleSelect,
	expandFlag,
	setRowSelect,
	rowSelect,
	handleApplyBestCpc,
	bestFlag,
	bestArr,
	bestCVRCPC

}) {
	const dispatch = useDispatch();
	const [weekFilter, setweekFilter] = useState(0);
	const [subData, setSubData] = useState([]);
	let [flag, setflag] = useState(false)


	useEffect(() => {
		weekData?.reverse()
	}, [weekData])

	useEffect(() => {
		if (placementData.length > 0) {
			placementData?.map((el, index) => {
				let a = campaignName.split(/\s+/);

				function isWordIncludedInObject(word, obj) {
					return word.type.toLowerCase().includes(obj.toLowerCase());
				}
				let find = defaultTargetAcos.find(word => a.some(obj => isWordIncludedInObject(word, obj)));
				if (find == undefined) {
					find = defaultTargetAcos.find(x => x.type == "All other")
				}

				if (el.dynamicTarget == true) {
					placementData[index].targetAcos = findAcos(el.defaultRecord, el.cvr);
				} else {
					if (index == 0) {
						placementData[index].targetAcos = find?.TOStargetAcos;
					}
					else if (index == 1) {
						placementData[index].targetAcos = find?.ROStargetAcos;
					}
					else if (index == 2) {
						placementData[index].targetAcos = find?.PPtargetAcos;
					}
				}
			})
		}

		setSubData(placementData);
	}, [placementData]);

	function findAcos(records, givenValue) {
		for (let record of records) {
			if (record.from <= givenValue && givenValue <= record.to) {
				return record.Acos;
			}
		}
		return 0; // Return null if no matching range is found
	}

	const columns1 = [

		{
			name: "Target Acos",
			selector: (row) => row.targetAcos,
			cell: (e, rowIndex) => {

				const changeEvent = ($event) => {

					let eventValue = Number($event.target.value)

					let crrObj = subData;
					let acosVal = eventValue;
					let TotalSales = e.sales;
					let TotalClick = e.clicks;
					let result;
					let Sales = 0
					let Units = 0
					// --applying condition if placement order=0 && clicks>=4
					if (subData) {
						for (let i = 0; i < subData.length; i++) {
							Sales = Sales + subData[i].sales;
							Units = Units + subData[i].orders;
						}
					}


					let AOV //if AOV  is set on setting page then, take that AOV, ELSE AOV =sales/units
					if (order == 0) {
						aovData.forEach(element => {
							let uppercase = element.campaignName.toUpperCase()
							let name = new RegExp(uppercase, 'i')
							if (name.test(campaignName)) {
								AOV = element.value
							}
						});
					}
					else {
						AOV = Sales / Units
					}

					if (!AOV) {
						AOV = Sales / Units
					}


					//target cpc calculations conditions
					if (crrObj[rowIndex].orders == 0 && crrObj[rowIndex].clicks >= 4) { //if placement level , order=0 and clicks>= 4 
						result = (1 / (crrObj[rowIndex].clicks + 1)) * (AOV) * (acosVal / 100)
					}

					// else if (crrObj[rowIndex].orders == 0 && crrObj[rowIndex].clicks < 4) {
					//   result = TOSPercentage
					// }
					// else if (crrObj[rowIndex].sales == 0 && crrObj[rowIndex].clicks < 4) {
					//   result = (Number(AOV) / crrObj[rowIndex].clicks + 1) * (acosVal * 100)

					// }
					else if (crrObj[rowIndex].sales == 0 && crrObj[rowIndex].clicks < 4) {
						result = (Number(AOV) / (Number(crrObj[rowIndex].clicks) + 1)) * (eventValue / 100)

					}

					else { // else
						if (crrObj[rowIndex].orders <= 3 && crrObj[rowIndex].cvr >= 50) { //if orders <=0 and cvr>=50
							result = crrObj[rowIndex].cpc + (crrObj[rowIndex].cpc * 0.5) // old cpc+ 50% oldcpc

						}

						else {// rest placements 
							result = (TotalSales / TotalClick) * (eventValue / 100);

						}
					}


					let finalVal = result.toFixed(2)

					if (isNaN(finalVal)) {
						finalVal = 0
					}

					// condition of min and max target cpc according to setting page
					crrObj[rowIndex].targetAcos = eventValue == '' ? 0 : eventValue;

					if (bestArr.includes(e.campaignId)) {

						crrObj[rowIndex].targetCPC = crrObj[rowIndex].targetCVRCPC == 1 ? crrObj[rowIndex].targetCPC : crrObj[rowIndex].bestCVRCPC
					}

					else {
						crrObj[rowIndex].targetCPC = eventValue != '' ? Number(finalVal) < minTargetCPC ? minTargetCPC : Number(finalVal) > maxTargetCPC ? maxTargetCPC : Number(finalVal) : 0;

					}
					setSubData([...subData]);


					// ------------tatgetcpc condtions---------//
					HandleTargetCpcCondition(crrObj, rowIndex)


					//  ---------child new bid adjustment calculation--------- //
					HandleChildBidAdjustment(e.campaignId, crrObj)


					// // --------- new limit calculation (cpc and limited cpc) ------------//
					// calLimiatedCpcANDtagetcpcAmazon(e, e.tagetCPCAmazon)


					// ----------- update campaign data --------//
					dispatch(childBidHandler())
				}

				return (
					<>
						<input onChange={changeEvent} onBlur={setflag(false)} value={e.targetAcos} type="text" className="cal" />
					</>
				);
			},

		},
		{
			name: "placement",
			selector: (row) => row.placement,
		},

		{
			name: "Bid Adjustment",
			width: "80px",
			selector: (row) => row.placementModifier ?? "-",
			cell: (e) => {
				return `${e.placementModifier ? e.placementModifier : 0}%`
			}
		},

		{
			name: "Target CPC In Amazon",
			sortable: true,
			width: "80px",
			selector: (row) => row.tagetCPCAmazon,
			cell: (e) => {
				return `$${e.tagetCPCAmazon}`

			}
		},
		{
			name: "Target CPC",
			sortable: true,
			selector: (row) => `$${row.targetCPC}`,
			// cell: (e) => {
			// 	if (bestArr.includes(e.campaignId)) {
			// 		return `$${e.targetCVRCPC == 1 ? e.targetCPC : e.bestCVRCPC}`
			// 	}

			// 	else {
			// 		return `$${e.targetCPC}`
			// 	}


			// }
		},

		{
			name: "Best CPC",
			sortable: true,
			selector: (row) => row.targetCVRCPC == 1 ? row.targetCPC : row.bestCVRCPC,
			cell: (e) => {

				if (bestArr.includes(e.campaignId)) {
					return e.bestCPC;
				}
				else {
					return e.targetCVRCPC == 1 ? e.targetCPC || 0 : e.bestCVRCPC || 0;
				}
			}
		},


		{
			name: 'Limited CPC',
			selector: (row) => `$${row.limitedCPC}`,
			cell: (e) => {
				const changeEvent = ($event) => {

					let eventValue = Number($event.target.value)
					e.limitedCPC = eventValue;

					//  ---------child new bid adjustment calculation--------- //
					HandleChildBidAdjustment(e.campaignId, placementData)


					dispatch(childBidHandler())
				}
				return (

					<>
						<input onChange={changeEvent}
							value={e.limitedCPC == undefined ? 0 : e.limitedCPC}
							type="number"
							className="cal"
							inputMode="numeric"
						/>
					</>
				)
			}

		},

		{
			name: "New Bid Adjustment",
			selector: (row) => row.newbidAdjustment,
			cell: (e) => {


				if (e.newbidAdjustment == undefined || e.newbidAdjustment == "undefined") {
					return '0%'
				}
				else {
					return `${e.newbidAdjustment}%`
				}
			}
		},

		{
			name: "Impressions",
			selector: (row) => row.impressions,

		},

		{
			name: "Clicks",
			selector: (row) => row.clicks,

		},
		{
			name: "CTR%",
			selector: (row) => row.ctr,
			cell: (e) => (
				`${e.ctr}%`
			)

		},
		{
			name: "Spend",
			selector: (row) => row.spend,
			cell: (e) => (
				`$${e.spend}`
			)

		},
		{
			name: "Sales",
			selector: (row) => row.sales,
			cell: (e) => (
				`$${e.sales}`
			)

		},
		{
			name: "Orders",
			selector: (row) => row.orders,
		},
		{
			name: "Units",
			selector: (row) => row.orders,
		},

		{
			name: "CVR%",
			selector: (row) => row.cvr,
			cell: (e) => (
				`${e.cvr}%`
			)
			// width: "70px",
		},
		{
			name: "ACOS%",
			selector: (row) => row.acos,
			cell: (e) => (
				`${e.acos}%`
			)
			// width: "70px",
		},
		{
			name: "CPC",
			selector: (row) => row.cpc,
			cell: (e) => (
				`$${e.cpc}`
			)
		},
		{
			name: "ROAS",
			selector: (row) => row.roas,
			// width: "70px",
		},
	];

	const handleWeekFilter = (val) => {
		setweekFilter(val);
	};

	const cal = () => {

		if (placementData?.length != 0 && placementData != undefined) {
			let crrObj = placementData;
			let Sales = 0
			let Units = 0

			for (let i = 0; i < crrObj?.length; i++) {
				Sales = Sales + crrObj[i].sales;
				Units = Units + crrObj[i].orders;
			}
			let AOV

			crrObj?.map((e, rowIndex) => {

				let eventValue = e.targetAcos;
				let TotalSales = e.sales;
				let TotalClick = e.clicks;

				let result;

				if (order == 0) {
					aovData.forEach(element => {
						let uppercase = element.campaignName.toUpperCase()
						let name = new RegExp(uppercase, 'i')
						if (name.test(campaignName)) {
							AOV = element.value
						}
					});
				}
				else {
					AOV = Sales / Units
				}

				if (!AOV) {
					AOV = Sales / Units
				}

				// --applying condition if placement order=0 && clicks>=4
				// If Orders = < 3 and CVR is >= 50% then use formula: (Old CPC + 50%)

				if (crrObj[rowIndex].orders == 0 && crrObj[rowIndex].clicks >= 4) {
					result = (1 / (crrObj[rowIndex].clicks + 1)) * (AOV) * (eventValue / 100)
				}

				else if (crrObj[rowIndex].sales == 0 && crrObj[rowIndex].clicks < 4) {
					result = (Number(AOV) / (Number(crrObj[rowIndex].clicks) + 1)) * (eventValue / 100)
				}
				else {
					if (crrObj[rowIndex].orders <= 3 && crrObj[rowIndex].cvr >= 50) {
						result = crrObj[rowIndex].cpc + (crrObj[rowIndex].cpc * 0.5)
					}
					else {
						result = (TotalSales / TotalClick) * (eventValue / 100);
					}
				}
				let finalVal = result.toFixed(2)

				if (isNaN(finalVal)) {
					finalVal = 0
				}

				crrObj[rowIndex].targetAcos = eventValue == '' ? 0 : eventValue;

				if (bestArr.includes(e.campaignId)) {

					crrObj[rowIndex].targetCPC = crrObj[rowIndex].targetCVRCPC == 1 ? crrObj[rowIndex].targetCPC : crrObj[rowIndex].bestCVRCPC
				}

				crrObj[rowIndex].targetCPC = eventValue != '' ? Number(finalVal) < minTargetCPC ? minTargetCPC : Number(finalVal) > maxTargetCPC ? maxTargetCPC : Number(finalVal) : 0

				// ------------tatgetcpc condtions---------//
				HandleTargetCpcCondition(crrObj, rowIndex)

			})


			crrObj[0].oldCPC = crrObj[0]?.targetCPC
			crrObj[1].oldCPC = crrObj[1]?.targetCPC
			crrObj[2].oldCPC = crrObj[2]?.targetCPC

			HandleChildBidAdjustment(crrObj && crrObj[0]?.campaignId, crrObj)

			setflag(false)
			dispatch(childBidHandler())
		}
	}

	// ------------tatgetcpc condtions-----------------//
	const HandleTargetCpcCondition = (crrObj, rowIndex) => {

		if (rowIndex == 2) {
			let place = crrObj;
			let all = 0;
			let one = 0;
			let two = 0;
			let placementWithClicksAndSale = {}
			let placementWithoutClicksAndSale = []

			for (let i = 0; i < place.length; i++) {

				if (place[i].clicks < 4 && place[i].orders == 0) {
					all++;
				}
				if (place[i].clicks > 0 && place[i].orders > 0) {
					one++;
				}
				else if (place[i].clicks >= 4 && place[i].orders == 0) {
					one++
				}
			}
			if (one == 1) {
				for (let i = 0; i < place.length; i++) {
					if (place[i].clicks > 0 && place[i].orders > 0) {
						placementWithClicksAndSale = place[i]
					}
					else if (place[i].clicks >= 4 && place[i].orders == 0) {
						placementWithClicksAndSale = place[i]
					}
					if (place[i].clicks < 4 && place[i].orders == 0) {
						two++;
						placementWithoutClicksAndSale.push(place[i])
					}
				}
			}

			if (all == 3) {
				setflag(true)

			}
			else if (one == 1 && two == 2) {
				placementWithoutClicksAndSale.map((el, i) => {
					let index = crrObj?.findIndex(x => x == el)
					crrObj[index].targetCPC = placementWithClicksAndSale.targetCPC
				})

				setflag(true)
			}
			else {
				place.map((el, i) => {
					if (place[i].orders == 0 && place[i].clicks < 4) {
						let lowestCPC = Math.min(
							place[(i + 1) % place.length].targetCPC, // Next object
							place[(i + 2) % place.length].targetCPC  // Next-to-next object
						);
						crrObj[i].targetCPC = lowestCPC
					}

				})
				setflag(true)
			}
		}

		crrObj.map((el, i) => {

			calLimiatedCpcANDtagetcpcAmazon(el)
		})

	}

	// --------- targetcpcamazon and limitedcpc ------------//
	const calLimiatedCpcANDtagetcpcAmazon = (e, targetCPCAmazon, cpc) => {

		//  targetcpcamazon (calculate based on keywordbid(new bidadjustemnt main))//
		let targetCpcAmazon;
		let queryResult = (Number(keywordBid) * Number(e.placementModifier)) / 100;
		let finalResult = Number(queryResult) + Number(keywordBid);
		e.tagetCPCAmazon = finalResult ? finalResult.toFixed(2) : 0;
		targetCpcAmazon = finalResult ? finalResult.toFixed(2) : 0;


		// limitedcpc (calculate based on targetcpcamazon) //


		let limitedCPCValue = 0;
		const M7 = Number(targetCpcAmazon);
		const N7 = Number(e.targetCPC);
		const H3 = limitValue.find(x => e.acos >= x.from && e.acos <= x.to)?.increaseBid;
		const H4 = limitValue.find(x => e.acos >= x.from && e.acos <= x.to)?.decreaseBid;



		function calculateResult(M7, N7, H3, H4) {
			if (M7 <= N7) {
				return Math.min(M7 * (1 + H3 / 100), N7);
			} else {
				return Math.max(M7 * (1 - H4 / 100), N7);
			}
		}

		limitedCPCValue = calculateResult(M7, N7, H3, H4);
		e.limitedCPC = limitedCPCValue



		let val = Number(limitedCPCValue) < minTargetCPC ? minTargetCPC : Number(limitedCPCValue) > maxTargetCPC ? maxTargetCPC : Number(limitedCPCValue)
		e.limitedCPC = val.toFixed(2)
	}


	//---------------- main * new bid adjustment  row value set ----------------//
	const HandleNewBidAdjustemntMainCol = (campaignId, val) => {


		let newCamapign = campaignData;
		let checkIndex = newCamapign.findIndex(x => x.campaignId == campaignId)
		newCamapign[checkIndex].newKeywordBid = Number(val);
		newCamapign[checkIndex].newbidAdjustment = Number(val);

		let Ids = JSON.parse(localStorage.getItem('ids')) || []
		campaignData.map(el => {
			// HandleSelect(el)
			if (Ids?.includes(el.campaignId) && campaignId == el.campaignId) {

				let findRecord = el;
				if ((Number(findRecord.keywordBid) === Number(val) &&
					Number(findRecord?.placementsData[0]?.placementModifier) === Number(findRecord?.placementsData[0]?.newbidAdjustment) &&
					Number(findRecord?.placementsData[1]?.placementModifier) === Number(findRecord?.placementsData[1]?.newbidAdjustment) &&
					Number(findRecord?.placementsData[2]?.placementModifier) === Number(findRecord?.placementsData[2]?.newbidAdjustment))) {

					setRowSelect((prevRowSelect) => prevRowSelect.filter(id => id !== findRecord.campaignId));
				}
				else {
					if (!rowSelect.includes(findRecord.campaignId)) {
						setRowSelect((prevRowSelect) => [...prevRowSelect, findRecord.campaignId]);
					}
				}
			}
		})

	}



	// ------------------newbidadjustemnt (child calcualtion)) ---------//

	const HandleChildBidAdjustment = (campaignId, data) => {

		let placement = data;
		let resule = 0
		if (placement[0]?.targetAcos != undefined && placement[1]?.targetAcos != undefined && placement[2]?.targetAcos != undefined) {

			//  newbidadjustemnt main(calculate based on target cpc) //
			let toptargetCPC = Number(placement[0]?.limitedCPC) || 0;
			let rostargetCPC = Number(placement[1]?.limitedCPC) || 0;
			let pptargetCPC = Number(placement[2]?.limitedCPC) || 0;

			let getLowestValue = Math.min(toptargetCPC, pptargetCPC, rostargetCPC)
			resule = getLowestValue;


			// --setting newbasebid as oldbasebid if rsule is 0 //

			if (resule == 0) {
				resule = keywordBid
			}

			// newbidadjustment first 2 row calculaetion(based on limited cpc)//


			if (placement[0].orders == 0 && placement[0].clicks < 4) {
				let val = Number(getLowestValue) * (Number(TOSPercentage) / 100);
				let sum = (Number(getLowestValue) + Number(val)).toFixed(2);
				placementData[0].limitedCPC = sum == "NaN" ? 0 : sum < 0 ? 0 : sum > 900 ? 900 : sum
			}
			let firstLimitedcpcRow = Number(placement[0]?.limitedCPC);
			let secondLimitedcpcRow = Number(placement[1]?.limitedCPC);
			let thirdLimitedcpcRow = Number(placement[2]?.limitedCPC);

			let FIRSTDiffrence = Number(firstLimitedcpcRow) / Number(resule);
			let firstVal = ((FIRSTDiffrence - 1) * 100).toFixed(0);
			placementData[0].newbidAdjustment = firstVal == "NaN" ? 0 : firstVal < 0 ? 0 : firstVal > 900 ? 900 : firstVal
			let SecondDiffrence = Number(secondLimitedcpcRow) / Number(resule);
			let secondVal = ((SecondDiffrence - 1) * 100).toFixed(0);
			placementData[1].newbidAdjustment = secondVal == "NaN" ? 0 : secondVal < 0 ? 0 : secondVal > 900 ? 900 : secondVal

			let thirdDiffrence = Number(thirdLimitedcpcRow) / Number(resule);
			let thirdVal = ((thirdDiffrence - 1) * 100).toFixed(0);
			placementData[2].newbidAdjustment = thirdVal == "NaN" ? 0 : thirdVal < 0 ? 0 : thirdVal > 900 ? 900 : thirdVal

			setSubData([...placementData]);
		}

		HandleNewBidAdjustemntMainCol(campaignId, resule)
		// HandleSelect()
	}

	useEffect(() => {
		cal(placementData)
	}, [placementData]);


	useEffect(() => {
		placementData?.map((crrObj, rowIndex) => {
			if (bestArr.includes(crrObj.campaignId)) {
				let bestCPC = crrObj.targetCVRCPC == 1 ? crrObj.targetCPC : crrObj.bestCVRCPC;
				crrObj.bestCPC = bestCPC;
				crrObj.newbidAdjustment = crrObj.placementModifier;
				if (crrObj.placement == "Top of Search" || crrObj.placement == 'Rest of Search') {
					crrObj.limitedCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
					crrObj.targetCPC = bestCPC >= crrObj.targetCPC ? bestCPC : crrObj.targetCPC;
				} else {
					crrObj.limitedCPC = bestCPC >= crrObj.targetCPC ? crrObj.targetCPC : bestCPC;
					crrObj.targetCPC = bestCPC >= crrObj.targetCPC ? crrObj.targetCPC : bestCPC;
				}
			}
		})
	}, [placementData, bestArr]);



	return (
		<>
			{localStorage.getItem('radio') == "Placement" ? (
				<DataTable
					className="table_content"
					columns={columns1}
					data={subData}
					fixedHeader
					loading={false}
					progressPending={false}
				/>
			) : (
				<>
					<div className="week_filter">
						<button
							onClick={() => {
								setArg({ ...arg, type: 0 })
								handleWeekFilter(0);
							}}
						>
							Day
						</button>
						<button
							onClick={() => {
								setArg({ ...arg, type: 1 })
								handleWeekFilter(1);
							}}
						>
							Week
						</button>
						<button
							onClick={() => {
								setArg({ ...arg, type: 2 })
								handleWeekFilter(2);
							}}
						>
							Month
						</button>
					</div>
					<div className=" weekly_table  mb-5 expand_table">
						<table className="table table-striped">
							<thead>
								<tr>
									<th className="date_show">
										{weekFilter == 0
											? "Day"
											: weekFilter == 1
												? "Week"
												: weekFilter == 2
													? "Month"
													: null}
									</th>
									<th>Organic Position</th>
									<th>Sponsored Position</th>
									<th>PPC Sales</th>
									<th>PPC Cost</th>
									<th> Profits</th>
									<th>Profit Margin</th>
									<th>ACOS</th>
									<th>Orders</th>
									<th>Units</th>
									<th>Conversion</th>
									<th>CPC</th>
									<th>Impressions</th>
									<th>Clicks</th>
									<th>CTR</th>
									<th>Cost Per Order</th>
									<th>Avg Sales Price</th>
								</tr>
							</thead>

							<tbody style={{ width: weekData?.length > 15 ? "auto" : "fit-content" }}>

								{weekData?.map((el) => {
									return (
										<tr>
											<td className="date_show">
												{weekFilter == 1 || weekFilter == 2 ? (
													<span>
														{moment(el.fromDate).format("DD-MM-YYYY")}
														<br />
														{moment(el.toDate).format("DD-MM-YYYY")}
													</span>
												) : (
													moment(el.toDate).format("DD-MM-YYYY")
												)}
											</td>
											<td>{el.organicPosition}</td>
											<td>{el.sponsoredPosition}</td>
											<td>{el.sales}</td>
											<td>{el.spend}</td>
											<td>{el.profit}</td>
											<td>{el.profitMargin}</td>
											<td>{el.acos}</td>
											<td>{el.orders}</td>
											<td>{el.orders}</td>
											<td>{el.cvr}</td>
											<td>{el.cpc}</td>
											<td>{el.impressions}</td>
											<td>{el.clicks}</td>
											<td>{el.ctr}</td>
											<td>{el.cpc}</td>
											<td>{el.avgSalesPrice}</td>
										</tr>
									);
								})}
							</tbody>


						</table>
					</div>
				</>
			)}
		</>
	);
}

export default ExpandTable;
