let campaignFilterData = [
   { label: 'All', value: '' },
   { label: 'Ranking', value: 'Ranking' },
   { label: 'Exact', value: 'Exact' },
   { label: 'Phrase', value: 'Phrase' },
   { label: 'Broad', value: 'Broad' },
   { label: 'Auto', value: 'Auto' },
   { label: 'ASIN', value: 'ASIN' },
   { label: 'All non ranking', value: 'All non ranking' },
   // { label: 'SB', value: 'SB' },
   //  { label: 'SD', value: 'SD' },
]


export default campaignFilterData