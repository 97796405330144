import axios from "axios";

const api = `${process.env.REACT_APP_API_BASE}/api/setting/`
const apiAOV = `${process.env.REACT_APP_API_BASE}/api/averageAOV/`
const authToken = localStorage.getItem('sellerToken')

const headers = { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json' };

const ApiCreateTargetCPC = (data) => {
    return axios.post(`${api}create`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiGetTargetCPC = (data) => {
    return axios.post(`${api}get`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiGetAverageAOV = (data) => {
    return axios.post(`${apiAOV}getAllAOV`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiCreateAverageAOV = (data) => {
    return axios.post(`${apiAOV}createAOV`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiUpdateAverageAOV = (id, data) => {
    return axios.post(`${apiAOV}updateAOV/${id}`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiDeleteAverageAOV = (data) => {
    return axios.delete(`${apiAOV}deleteAOV/${data}`, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiCreateLimitValue = (data) => {
    return axios.post(`${api}createLimitValue`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiSaveAcos = (data) => {
    return axios.post(`${api}defaultTargetAcos/saveAcos`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiSaveOtherAcos = (data) => {
    return axios.post(`${api}defaultTargetAcos/saveAcosSBSD`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiGetAcos = (data) => {
    return axios.post(`${api}defaultTargetAcos/getAcos`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiTopModifier = (data) => {
    return axios.post(`${api}TOSPercentage`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiAdjustStatus = (data) => {
    return axios.post(`${api}excludeAdjustedCampaigns`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiLimitSave = (data) => {
    return axios.post(`${api}createLimitedCPC`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

export { ApiLimitSave,ApiSaveOtherAcos, ApiAdjustStatus, ApiTopModifier,ApiGetAcos, ApiSaveAcos, ApiCreateTargetCPC, ApiGetTargetCPC, ApiGetAverageAOV, ApiCreateLimitValue, ApiCreateAverageAOV, ApiUpdateAverageAOV, ApiDeleteAverageAOV }

