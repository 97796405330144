import logo from './logo.svg';
import './App.scss';
import { useContext, useEffect, useState } from 'react';
import DefaultLayout from './defaultLayout/DefaultLayout';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import routes from './router/routes'
import 'react-toastify/dist/ReactToastify.css';
import { UseContext } from './usecontext/useContext';


function App() {


  function RequireAuth() {
    let location = useLocation();
    const token = localStorage.getItem('sellerToken');

    if (!token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Outlet />;
  }
  


  return (
    <>
      <ToastContainer />
      <UseContext>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />}></Route>
          <Route exact path="/" element={<RequireAuth />}>
            {
              routes.map((pages, i) => {
                return (
                  <Route exact path='/' element={<DefaultLayout />}>
                    <Route path={pages.path} exact key={i} element={pages.component} />
                  </Route>
                )
              })
            }
          </Route>
        </Routes>
      </BrowserRouter>
      </UseContext>
    </>
  );
}

export default App;
