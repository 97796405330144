import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
	ApiCampaignList, ApiUnmange, ApiChild, ApisaveAcos, ApisaveInAmazon
} from "../../../api-wrapper/sb/apis";
import { useDispatch } from "react-redux";
import { childBidHandler } from "../../../redux/action";
import Toast from "../../../helper/toast/Toast";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import ExpandTable from "./ExpandTable";
import campaignFilterData from "./col";
import { ProfileContext } from "../../../usecontext/useContext";
import { useContext } from "react";


function CampaignTable({ campaignId, fromDate, toDate, }) {
	const dispatch = useDispatch();
	const { orderSwitch, minTargetCPC, setminTargetCPC, maxTargetCPC, setmaxTargetCPC, limitValue, setlimitValue, orderVal, setOrderVal, setOrderSwitch } = useContext(ProfileContext);
	const [profileId, setprofileId] = useState(localStorage.getItem("profileId") || "");

	const [campaignFilterName, setCampaignFilterName] = useState("");
	const [searchEL, setsearchEL] = useState("");
	const [TOSPercentage, setTOSPercentage] = useState();
	const [defaultTargetAcos, setdefaultTargetAcos] = useState();
	const [arg, setArg] = useState({
		pageNo: 1,
		perPage: localStorage.getItem('perPage') || 25,
		searchType: campaignFilterName,
		search: searchEL,
	});
	const [campaignData, setcampaignData] = useState([]);
	const [fieldName, setfieldName] = useState('');
	const [sortType, setsortType] = useState('')
	const [subval, setsubval] = useState([]);
	const [campaignIds, setCampaignIds] = useState([]);
	const [campaignNamesArr, setCampaignNamesArr] = useState([]);
	const [aovData, setaovData] = useState([])
	const [expandedRows, setExpandedRows] = useState([]);
	const CustomCollapsedIcon = () => <AiOutlinePlus />;
	const CustomExpandedIcon = () => <AiOutlineMinus />;
	const customExpandIcon = {
		collapsed: <CustomCollapsedIcon />,
		expanded: <CustomExpandedIcon />,
	};
	const [perPage, setPerPage] = useState(localStorage.getItem('perPage') || 25);
	const [totalRecords, settotalRecords] = useState(0);
	const [rowSelect, setRowSelect] = useState([]);
	const [selectALL, setSelectALL] = useState(false);
	const limitArray = [25, 100, 500, 1000];
	const paginationRowsPerPageOptions = [...limitArray, totalRecords];
	paginationRowsPerPageOptions.sort((a, b) => a - b);
	const [bestFlag, setbestFlag] = useState(false);
	const [bestArr, setBestArr] = useState([]);
	const [childData, setChilddata] = useState();
	const [bestCVRCPC, setBestCVRCPC] = useState([]);

	const columns = [
		{
			name: (
				<input
					type="checkbox"
					onChange={(e) => handleSelectAll(e)}
					checked={selectALL}
				/>
			),
			width: "50px",
			cell: (row) => (
				<input

					type="checkbox"
					checked={rowSelect.includes(row.campaignId)}
					onChange={(e) => handleRowSelect(e, row.campaignId)}
				/>
			),
		},

		{
			name: "Target Acos",
			sortable: true,
			selector: (row) => row.targetAcos,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Campaigns",
			selector: (row) => row.campaignName,
			sortable: true,
			cell: (e) => {
				return <>
					<div className={e.status == "ENABLED" ? "green_dot dot" : "red_dot dot"}>
						<p></p>
						<div>{e.campaignName}</div>
					</div>
				</>;
			},
		},


		{
			name: "Target CPC In Amazon",
			sortable: false,
			width: "80px",
			selector: (row) => row.tagetCPCAmazon,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},

		{
			name: "Target CPC",

			sortable: true,
			selector: (row) => row.targetCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},

		{
			name: "Best CPC",
			sortable: true,
			selector: (row) => row.bestCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},
		{
			name: "Limited CPC",

			sortable: true,
			selector: (row) => row.limitedCPC,
			cell: (e) => {
				return <>{`-`}</>;
			},
		},

		{
			name: "Impressions",
			selector: (row) => row.impressions,
			sortable: true,
		},

		{
			name: "Clicks",
			selector: (row) => row.clicks,
			sortable: true,
		},
		{
			name: "CTR%",
			sortable: true,
			selector: (row) => row.ctr,
			cell: (e) => `${e.ctr}%`,
		},
		{
			name: "Spend",
			sortable: true,
			selector: (row) => row.spend,
			cell: (e) => `$${e.spend}`,
		},
		{
			name: "Sales",
			sortable: true,
			selector: (row) => row.sales,
			cell: (e) => `$${e.sales}`,
		},
		{
			name: "Orders",
			selector: (row) => row.orders,
			sortable: true,
			// width: "70px",
		},
		{
			name: "Units",
			selector: (row) => row.units,
			sortable: true,
			// width: "70px",
		},

		{
			name: "CVR%",
			sortable: true,
			selector: (row) => row.cvr,
			cell: (e) => `${e.cvr}%`,
		},

		{
			name: "ACOS%",
			sortable: true,
			selector: (row) => row.acos,
			cell: (e) => `${e.acos}%`,
		},
		{
			name: "CPC",

			sortable: true,
			selector: (row) => row.cpc,
			cell: (e) => `$${e.cpc}`,
		},


		{
			name: "ROAS",
			selector: (row) => row.roas,
			sortable: true,
		},

	];



	useEffect(() => {
		localStorage.setItem('SBtarget', JSON.stringify([]));

	}, [])

	useEffect(() => {

		let getStorage = rowSelect;

		let check = campaignData.every(x => getStorage.includes(x.campaignId));

		if (check) {
			setSelectALL(true);
		} else {
			setSelectALL(false);
		}

	}, [rowSelect, campaignData]);



	useEffect(() => {
		if (campaignIds?.length != 0) {
			childHandler()
		}
	}, [campaignIds, arg.type]);

	useEffect(() => {
		getCampaignData(arg)
	}, [fromDate, toDate, arg, orderSwitch]);

	const handleRowSelect = (e, rowId) => {

		const isChecked = e.target.checked;

		if (isChecked) {

			if (!rowSelect.includes(rowId)) {
				setRowSelect([...rowSelect, rowId]);
			}

		} else {

			const updatedSelectedRows = rowSelect.filter((id) => id !== rowId);
			setRowSelect(updatedSelectedRows);
		}
	};

	const handleSelectAll = (e) => {

		if (e.target.checked) {
			let ids = campaignData.map(x => x.campaignId)
			let prevSelect = rowSelect;
			let item = [...ids, ...prevSelect]
			let uniqueChars = [...new Set(item)];
			setRowSelect(uniqueChars)
			setSelectALL(true)
		}
		else {

			setSelectALL(false)
			let data = rowSelect || [];
			let aSet = new Set(campaignData.map(item => item.campaignId));
			let idsNotInA = data.filter(id => !aSet.has(id));
			setRowSelect(idsNotInA)
		}
	}

	const getCampaignData = async () => {

		if (fromDate && toDate) {
			let data = {
				profileId: profileId,
				isManage: true,
				fromDate: fromDate,
				toDate: toDate,
				...arg,
			};
			if (orderSwitch) {
				data = {
					...data,
					filterType: "Orders"
				}
			}
			else {
				data = {
					...data,
					filterType: "Date"
				}
			}
			if (!limitArray?.includes(parseInt(data?.perPage))) {
				data = {
					...data,
					perPage: "ALL"
				}
			}
			try {

				const e = await ApiCampaignList(data);
				if (e?.isSuccess) {
					if ((!limitArray?.includes(data?.perPage)) || e?.totalRecords < data?.perPage || data?.perPage == "ALL") {
						setPerPage(e.campaignsResult?.length)
						localStorage.setItem("perPage", e.campaignsResult?.length)
					}
					settotalRecords(e.totalRecords);
					setTOSPercentage(e.TOSPercentage)
					setcampaignData(e.campaignsResult);
					setExpandedRows([])
					setCampaignIds([])
					setminTargetCPC(e.minTargetCPC)
					setmaxTargetCPC(e.maxTargetCPC)
					setlimitValue(e.limitedCPCValue?.conditions)
					setaovData(e.getAOVData)
					setdefaultTargetAcos(e.defaultTargetAcos)
					setOrderVal(e.defaultOrder || "")
				} else {
					Toast.error(e?.message);
				}
			} catch (error) {
				Toast.error("Something went wrong");
			} finally {
			}
		}
	};
	const ExpandedComponent = useCallback(
		({ data }) => {

			return (
				<div
					className="sub_table campaign_table campaign_sub sb_sub"
					key={data.campaignId}
				>
					<ExpandTable
						arg={arg}
						setArg={setArg}
						campaignName={data?.campaignName}
						placementData={data?.targetData}
						campaignData={campaignData}
						setsubval={setsubval}
						aovData={aovData}
						order={data?.orders}
						minTargetCPC={minTargetCPC}
						maxTargetCPC={maxTargetCPC}
						limitValue={limitValue}
						setcampaignData={setcampaignData}
						TOSPercentage={TOSPercentage}
						defaultTargetAcos={defaultTargetAcos}
						setRowSelect={setRowSelect}
						rowSelect={rowSelect}
						setbestFlag={setbestFlag}
						bestFlag={bestFlag}
						bestArr={bestArr}
						handleSelectTarget={handleSelectTarget}
						setChilddata={setChilddata}
						bestCVRCPC={bestCVRCPC}
					/>
				</div>
			);
		},
		[campaignData, bestArr, bestCVRCPC]
	);


	const childHandler = () => {

		let data = {
			profileId: profileId,
			campaignIds: campaignNamesArr,
			fromDate: fromDate,
			toDate: toDate,

		}
		if (orderSwitch) {
			data = {
				...data,
				filterType: "Orders"
			}
		}
		else {
			data = {
				...data,
				filterType: "Date"
			}
		}
		ApiChild(data)
			.then((res) => {
				let oldData = campaignData
				if (res.isSuccess) {
					dispatch(childBidHandler(subval))
					setsubval([...subval])
					if (res?.targetSubResult.length != 0) {
						oldData.map(item => {
							res?.targetSubResult?.find(el => {
								if (el.campaignId == item.campaignId) {
									item.targetData = el.targetData
								}
							})

						})

					}
					setcampaignData(oldData)

				}
			}).catch((err) => {
				Toast.error("Something went wrong");
			});
	}



	const handleRowExpandToggle = (expanded, row) => {

		if (expanded) {

			setExpandedRows([...expandedRows, row]);
			setCampaignIds([...campaignIds, row.campaignName])
			setCampaignNamesArr([...campaignNamesArr, row.campaignId])
			localStorage.removeItem('SBtarget')

		} else {

			setExpandedRows(
				expandedRows.filter((id) => id.campaignName !== row.campaignName)
			);
			setCampaignIds(campaignIds.filter((id) => id !== row.campaignName))
			setCampaignNamesArr(campaignNamesArr.filter((id) => id !== row.campaignId))

		}
	};


	const handleExpandAllClick = () => {
		if (expandedRows.length === 0) {
			const allRowIds = campaignData.map((row) => row.campaignName);
			setCampaignIds(allRowIds)
			setExpandedRows(campaignData);
			const compaignIds = campaignData.map((row) => row.campaignId)
			setCampaignNamesArr(compaignIds)
			localStorage.setItem('selectedItem', compaignIds)
			localStorage.removeItem('currentDataId')

		} else {
			localStorage.setItem('SBtarget', JSON.stringify([]));
			setExpandedRows([]);
			setCampaignIds([])
			setCampaignNamesArr([])

		}
	};


	// -------- weekly table data --------------//


	// ---pagination ---//
	const handlePageChange = (page) => {
		setExpandedRows([])
		setArg({
			...arg,
			pageNo: page,
			perPage: perPage
		})

		setSelectALL(false)
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setArg({
			...arg,
			pageNo: page,
			perPage: newPerPage
		})

		localStorage.setItem('perPage', newPerPage);
		setPerPage(newPerPage)


		setExpandedRows([]);
	};



	const handleSort = (column, sortDirection) => {
		let field = ''

		if (column.name == 'Target CPC') {
			field = 'targetCPC'
		}
		else if (column.name == 'Bid') {
			field = 'bid'
		}
		else if (column.name == 'Campaigns') {
			field = 'campaignName'
		}
		else if (column.name == 'Best CPC') {
			field = 'bestCPC'
		}
		else if (column.name == 'Limited CPC') {
			field = 'limitedCPC'
		}
		else if (column.name == 'Impressions') {
			field = 'impressions'
		}
		else if (column.name == 'Clicks') {
			field = 'clicks'
		}
		else if (column.name == 'CTR%') {
			field = 'ctr'
		}
		else if (column.name == 'Spend') {
			field = 'spend'
		}
		else if (column.name == 'Sales') {
			field = 'sales'
		}
		else if (column.name == 'Orders') {
			field = 'orders'
		}
		else if (column.name == 'Units') {
			field = 'units'
		}
		else if (column.name == 'CVR%') {
			field = 'cvr'
		} else if (column.name == 'ACOS%') {
			field = 'acos'
		} else if (column.name == 'CPC') {
			field = 'cpc'
		} else if (column.name == 'ROAS') {
			field = 'roas'
		}
		setfieldName(field)
		let sorting = ''
		if (sortDirection == 'asc') {
			sorting = '1'
		}
		else {
			sorting = '-1'
		}
		setsortType(sorting)
		setArg({ fieldName: field, sortType: sorting })
	};
	const HandleManageCampaign = () => {
		let filterData = rowSelect;

		let data = {
			isManage: false,
			campaignIds: filterData,
			profileId: profileId,
			type: "Sponsored Brands"
		};

		ApiUnmange(data)
			.then((res) => {
				if (res.isSuccess) {
					Toast.success(res.message);
					getCampaignData()
				}
			}).catch((err) => {
				Toast.error("somthing went wrong!!");
			});
	}

	const handleApplyBestCpc = () => {
		setBestCVRCPC([])
		let oldCampaign = campaignData;
		let getId = rowSelect;
		let findPlacement = oldCampaign.filter(campaign => getId.includes(campaign.campaignId) && campaign?.targetData);
		setBestArr(findPlacement.map(x => x.campaignId))
	};


	const handleSaveACOS = () => {
		let sendArr = []
		let arr = JSON.parse(localStorage.getItem('SBtarget')) || [];
		let campaign = campaignData.filter(x => x.targetData)
		let target = campaign.map(item => item.targetData || []).flat();
		let filteredData = target?.filter(x => arr.includes(x.targetId)) || [];

		if (filteredData?.length == 0) {
			Toast.error("Target ACOS is not selected !!");
		}
		else {
			sendArr = filteredData?.map(item => ({
				targetAcos: item.targetAcos,
				targetId: item.targetId,
				tagetCPCAmazon: Number(item.tagetCPCAmazon)
			}));

			let data = {
				targetData: sendArr,
				profileId: profileId,
				campaignType: "Sponsored Brands"
			};
			ApisaveAcos(data)
				.then((res) => {
					if (res.isSuccess) {
						Toast.success(res.message);
						getCampaignData()
					}
				}).catch((err) => {
					Toast.error("somthing went wrong!!");
				});
		}

	}
	const handleSelectTarget = (e, rowId, row) => {

		const isChecked = e.target.checked;
		let arr = JSON.parse(localStorage.getItem('SBtarget')) || [];

		if (isChecked) {
			if (!arr.includes(rowId)) {
				arr.push(rowId);
				localStorage.setItem('SBtarget', JSON.stringify(arr));
			}
		} else {
			const data = arr.filter((data) => data !== rowId);

			localStorage.setItem('SBtarget', JSON.stringify(data));
		}

	};

	const handelApplyOnAmazon = () => {

		let sendArr = []
		let arr = JSON.parse(localStorage.getItem('SBtarget')) || [];
		let campaign = campaignData.filter(x => x.targetData)
		let target = campaign.map(item => item.targetData || []).flat();

		let filteredData = target?.filter(x => arr.includes(x.targetId)) || [];

		if (filteredData?.length == 0) {
			Toast.error("Target ACOS is not selected !!");
		}
		else {

			sendArr = filteredData?.map(item => ({
				targetId: item.targetId,
				adGroupId: item.adGroupId,
				bid: Number(item.limitedCPC),
				// bid: Number(item.tagetCPCAmazon),
				targetName: item.targetName
			}));

			let data = {
				targetData: sendArr,
				profileId: profileId,
				applyOn: "Amazon"
			};
			ApisaveInAmazon(data)
				.then((res) => {
					if (res.isSuccess) {
						Toast.success(res.message);
						getCampaignData()
					}
				}).catch((err) => {
					Toast.error("somthing went wrong!!");
				});
		}
	}

	const handleSearch = () => {
		setCampaignNamesArr([]);
		setRowSelect([]);
		localStorage.setItem('SBtarget', JSON.stringify([]));
		setArg({ ...arg, search: searchEL })
	}

	const handleOtherFilter = (el) => {
		setRowSelect([]);
		setCampaignNamesArr([]);
		localStorage.setItem('SBtarget', JSON.stringify([]));
		setCampaignFilterName(el.value);
		setArg({ ...arg, searchType: el.value, perPage: perPage, pageNo: 1 })
	}
	return (
		<>
			<div className="data_content mt-0 mb-1 mb-md-2 ">
				<div className="data_table campaign_table sb_table scrollable_table">
					<div className="campaign_btn main_campaign_res">
						<div>
							<button onClick={handleExpandAllClick} className="expand-btn w-100">
								{expandedRows?.length === campaignData?.length
									? "Collapse All"
									: "Expand All"}
							</button>
						</div>

						<div className="search-btn">
							<div className="inner-search-btn">
								<input
									type="search"
									class="form-control"
									placeholder="Search Campaign"
									onChange={(e) => setsearchEL(e.target.value)}
								/>
								<button class="btn btn-primary" onClick={handleSearch}>
									<i class="fas fa-search"></i>
								</button>

							</div>
						</div>



						<div className={`vertical_table `}>
							{campaignFilterData?.map((el) => {
								return (
									<>
										<button
											className={`${campaignFilterName == el.value ? "activeFilter" : ""
												}`}
											onClick={() => handleOtherFilter(el)}
										>
											{el.label}
										</button>
									</>
								);
							})}
						</div>
						<div className="d-flex justify-content-end" >

							<div className="second_row_btn" >
								<button className="expand_btn w-100" onClick={() => handleApplyBestCpc()}>Apply Best CPC</button>
							</div>
							<div className="second_row_btn" >
								<button className="expand_btn w-100" onClick={() => HandleManageCampaign()}>Manage Campaign</button>
							</div>
							<div className="second_row_btn" >
								<button className="expand_btn w-100" onClick={() => handleSaveACOS()}>Save In DB</button>
							</div>
							<div className="second_row_btn" >
								<button className="expand_btn w-100" onClick={() => handelApplyOnAmazon()}>Apply On Amazon</button>
							</div>
						</div>
					</div>

					<DataTable
						title=""
						columns={columns}
						data={campaignData}
						onSort={handleSort}
						key={perPage}
						expandableRows
						expandableRowsComponent={ExpandedComponent}
						expandableIcon={customExpandIcon}
						onRowExpandToggled={handleRowExpandToggle}
						isRowExpandable
						pagination
						expandableRowExpanded={(row) => {
							return campaignIds?.includes(row?.campaignName)
						}}
						paginationTotalRows={totalRecords}
						paginationPerPage={perPage}
						paginationRowsPerPageOptions={paginationRowsPerPageOptions}
						paginationServer
						onChangePage={handlePageChange}
						onChangeRowsPerPage={handlePerRowsChange}
						keyField="campaignId"
					/>
				</div>
			</div>
		</>
	);
}


export default CampaignTable;
