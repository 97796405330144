import axios from "axios";

const api = `${process.env.REACT_APP_API_BASE}/api/placement/`
const settingApi = `${process.env.REACT_APP_API_BASE}/api/setting/`
const authToken = localStorage.getItem('sellerToken')
const filterApi = `${process.env.REACT_APP_API_BASE}/api/filter/`

const headers = { Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json' };


const ApiCampaignList = (data) => {
    return axios.post(`${api}listCampaignName`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApiPlacementListByCampaign = (data) => {
    return axios.post(`${api}getPlacementByCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiPlacementList = (data) => {

    return axios.post(`${api}getPlacement`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiPlacementListMange = (data) => {

    return axios.post(`${settingApi}getUnmanagedCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiWeeklyList = (data) => {


    return axios.post(`${api}getDateFilterPlacement`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiFilterList = (data) => {
    return axios.post(`${filterApi}getAllFilter`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}


const ApiCustomFilter = (data) => {
    return axios.post(`${filterApi}getAllFilterWithOutPagination`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}


const ApiChild = (data) => {
    return axios.post(`${api}getChildData`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}
const ApibulkACOS = (data) => {
    return axios.post(`${api}applyTargetAcos`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiSaveToDb = (data) => {
    return axios.post(`${api}saveAcosCpcToDB`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiUtilityGenerateReport = (data) => {
    return axios.post(`${api}utilityGenerateReport`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiGetUtilityHistory = (data) => {
    return axios.post(`${api}getUtilityHistory`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiUnmange = (data) => {
    return axios.post(`${settingApi}updateUnmanagedCampaign`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApiOrderSave = (data) => {
    return axios.post(`${api}saveDefaultOrder`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

const ApireverseBidChange = (data) => {
    return axios.post(`${api}reverseBidChange`, data, { headers })
        .then(res => res.data).catch(res => res.data)
}

export { ApiPlacementListMange, ApiOrderSave, ApiUnmange, ApiCustomFilter, ApiWeeklyList, ApiCampaignList, ApiPlacementList, ApiFilterList, ApiChild, ApibulkACOS, ApiSaveToDb, ApiUtilityGenerateReport, ApiGetUtilityHistory, ApireverseBidChange }

